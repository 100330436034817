<template>
  <a-card class="page-container">
    <div class="title_box">
      <h1 class="main-title">{{ activity_name }}</h1>
      <div class="danger_btn">
        <a-button
            type="primary"
            icon="plus"
            @click="pageJump('add')"
        >
          创建KPI
        </a-button>
      </div>
    </div>

    <a-table
        :columns="columns"
        :data-source="data_list"
        :pagination="false"
        :scroll='{ x: 1500,y: "calc(100vh - 200px)"}'
        :rowKey="(record, index) => index"
        :loading="loading"
    >
      <div slot="end_time" slot-scope="text, record">
        <div>{{ record.start_time }} 至 {{ record.end_time }}</div>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a class="del" style="color:red;" @click="clickRowKey(1, record)">删除</a>
        <a-divider type="vertical"/>
        <a @click="clickRowKey(2, record)">编辑</a>
      </div>
    </a-table>
  </a-card>
</template>

<script>
import _https from "@/api/KPI_list";

export default {
  name: "actKpiList",
  data() {
    return {
      activity_name: this.$route.query.activity_name || "",
      activity_id: this.$route.query.activity_id || "",
      principal_id: this.$route.query.principal_id || "",
      columns: [
        {
          dataIndex: "name",
          width: 300,
          title: "KPI名称"
        },
        {
          dataIndex: "standard_count",
          width: 90,
          title: "指标数量"
        },
        {
          dataIndex: "end_time",
          scopedSlots: {customRender: "end_time"},
          title: "考核时间",
          width: 300
        },
        {
          dataIndex: "",
          title: "操作",
          width: 200,
          scopedSlots: {customRender: "operation"}
        }
      ],
      data_list: [],
      loading: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      let params = {
        activity_id: this.activity_id
      };
      _https.getList(params).then(res => {
        this.loading = false;
        if(res.code == 200) {
          this.data_list = res.data.list || [];
        } else {
          this.$message.error("获取KPI列表失败");
        }
      });
    },
    pageJump(type) {
      switch(type) {
        case "add":
          this.$router.push({
            name: "actManage:kpiEdit",
            query: {
              activity_id: this.activity_id,
              activity_name: this.activity_name,
              principal_id: this.principal_id
            }
          });
          break;
      }
    },
    clickRowKey(type, record) {
      switch(type) {
        case 1:
          let that = this;
          this.$confirm({
            title: "你确定要删除该KPI吗？",
            okText: "确定",
            cancelText: "取消",
            onOk() {
              _https.deleteKpi(record.id).then(res => {
                if(res.code == 200) {
                  that.$message.success("KPI删除成功");
                  that.getList();
                } else {
                  that.$message.error(res.message || "KPI删除失败");
                }
              });
            }
          });
          break;
        case 2:
          this.$router.push({
            name: "actManage:kpiEdit",
            query: {
              id: record.id,
              activity_id: this.activity_id,
              principal_id: this.principal_id,
            }
          });
          break;
        default:
          console.log("触发");
      }
    } // 删除/编辑
  }
};
</script>

<style lang="less" scoped>
.page-container {
  padding: 0 !important;
}

.title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
</style>
